import React, { Component, useState } from 'react';
import { BiSolidUser, BiSolidEnvelope, BiSolidLockOpenAlt } from "react-icons/bi";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Register.css';
import images from '../../constantes/images';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader"; // Importando o ClipLoader do react-spinners

const strengthLabels = ["weak", "medium", "medium", "strong"];

const PasswordStrength = ({ onChange, password }) => {
    const [strength, setStrength] = useState('');

    const getStrength = (password) => {
        let strengthIndicator = -1;

        if (/[a-z]/.test(password)) strengthIndicator++;
        if (/[A-Z]/.test(password)) strengthIndicator++;
        if (/\d/.test(password)) strengthIndicator++;
        if (/[^a-zA-Z0-9]/.test(password)) strengthIndicator++;

        if (password.length >= 16) strengthIndicator++;

        return strengthLabels[strengthIndicator];
    };

    function handleChangePassword(e) {
        setStrength(getStrength(e.target.value));
        onChange(e);
    }

    return (
        <div className="app_login-input-box">
            <span className="icon"><BiSolidLockOpenAlt /></span>
            <input
                type="password"
                name='password'
                spellCheck="false"
                value={password}
                onChange={handleChangePassword}
            />
            <label>Password</label>
            <div className={`bars ${strength}`}>
                <div></div>
            </div>
            <div className="strength">{strength && `${strength} password`}</div>
        </div>
    );
}

class Register extends Component {

    constructor(props) {
        super();
        this.state = {
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
            loading: false, // Estado de carregamento
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        let target = e.target;
        let value = target.value;
        let name = target.name;
        this.setState({
            [name]: value
        });
    }

    insertData(nonce, navigate) {
        this.setState({ loading: true }); // Inicia o carregamento

        axios.get(`https://claudiasanches.pt/?json=user/register&username=${this.state.username}&email=${this.state.email}&nonce=${nonce}&user_pass=${this.state.password}&insecure=cool`)
            .then(res => {
                toast.success('User registered successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => {
                    navigate('/login'); // Redirecionar para a página de login após 2 segundos
                    this.setState({ loading: false }); // Encerra o carregamento
                }, 2000);
            }).catch(error => {
                console.log(error.response);
                toast.error('Error registering user: ' + (error.response ? error.response.data.error : error.message), {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                this.setState({ loading: false }); // Encerra o carregamento em caso de erro
            });
    }

    getWPnonce(navigate) {
        axios.get('https://claudiasanches.pt/?json=get_nonce&controller=user&method=register')
            .then(res => {
                this.insertData(res.data.nonce, navigate);
            }).catch(error => {
                console.log(error);
                toast.error('Error getting nonce: ' + (error.response ? error.response.data : error.message), {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
    }

    handleSubmit(e, navigate) {
        e.preventDefault();
        this.getWPnonce(navigate);
    }

    render() {
        const { loading } = this.state;

        return (
            <>
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}
                    >
                        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
                    </div>
                ) : (
                    <RegisterForm
                        state={this.state}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                    />
                )}
                <ToastContainer />
            </>
        );
    }
}

const RegisterForm = ({ state, handleChange, handleSubmit }) => {
    const navigate = useNavigate();

    return (
        <div className='app_register-container'>
            <div className='app_register-content'>
                <div className="app_register-right">
                    <h2>Register</h2>
                    <p>Do you have an account? <Link to="/login">Login</Link></p>
                    <div className='app_register-form'>
                        <form onSubmit={(e) => handleSubmit(e, navigate)}>
                            <div className="app_register-input-box">
                                <span className="icon"><BiSolidUser /></span>
                                <input
                                    type="text"
                                    name='username'
                                    value={state.username}
                                    onChange={handleChange}
                                />
                                <label>Username</label>
                            </div>
                            <div className="app_register-input-box">
                                <span className="icon"><BiSolidEnvelope /></span>
                                <input
                                    type="email"
                                    name='email'
                                    value={state.email}
                                    onChange={handleChange}
                                />
                                <label>Email</label>
                            </div>
                            <PasswordStrength
                                password={state.password}
                                onChange={handleChange}
                            />
                            <div className="app_register-input-box">
                                <span className="icon"><BiSolidLockOpenAlt /></span>
                                <input
                                    type="password"
                                    name='passwordConfirm'
                                    value={state.passwordConfirm}
                                    onChange={handleChange}
                                />
                                <label>Confirm Password</label>
                            </div>
                            <div className='app_register-button'>
                                <button type="submit" className="app_register-btnlog">Register</button>
                            </div>
                        </form>
                    </div>
                    <div className='app_register-logo'>
                        <Link to='/' className='app_register-logo-link'><img src={images.logo_horizontal} alt="Logo" /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
