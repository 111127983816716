import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/Navbar-Principal/NavBar';
import SlideShow from '../SlideShow/SlideShow';
import ClinicaArea from '../ClinicaArea/ClinicaArea';
import About from '../About/About';
import Footer from '../Footer/Footer';
import Banner from "../Banners/BannerLoja/Banner";
import ClipLoader from "react-spinners/ClipLoader"; // Importando o spinner do react-spinners
import './Layout.css';

function HomePage(props) {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadComponents = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setRemoveLoading(true);
      } catch (err) {
        setError(err);
        setRemoveLoading(true);
      }
    };

    loadComponents();
  }, []);

  useEffect(() => {
    if (!removeLoading) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    }
  }, [removeLoading]);

  if (error) {
    return <div>Error loading page: {error.message}</div>;
  }

  return (
    <div>
      {!removeLoading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          <ClipLoader size={50} color={"#123abc"} loading={!removeLoading} /> {/* Usando ClipLoader */}
        </div>
      )}
      <div className={`content ${removeLoading ? 'loaded' : ''}`}>
        <NavBar
          isLoggedIn={props.isLoggedIn}
          setIsLoggedIn={props.setIsLoggedIn}
          setUsername={props.setUsername}
          serverMessage={props.serverMessage}
          setServerMessage={props.setServerMessage}
        />
        <SlideShow />
        <About />
        <ClinicaArea />
        <Banner isLoggedIn={props.isLoggedIn} />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
