import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import WooConfig from '../../utils/WooConfig';
import fetchPostsByCategory from '../../utils/fetchServicos';
import './ClinicaArea.css';

const ClinicaArea = () => {
  const [servicos, setServicos] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef(null);
  const categoryId = 19;

  useEffect(() => {
    const fetchRecentData = async () => {
      try {
        const posts = await fetchPostsByCategory(WooConfig.usernameAdmin, WooConfig.passwordAdmin, categoryId);
        setServicos(posts);
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchRecentData();
  }, []);

  useEffect(() => {
    if (servicos.length === 0) return;

    gsap.registerPlugin(ScrollTrigger);

    const container = containerRef.current;
    const sections = gsap.utils.toArray(container.querySelectorAll('.panel'));

    sections.forEach((section, i) => {
      const title = section.querySelector('.information-title');
      const excerpt = section.querySelector('.information-excerpt');
      const link = section.querySelector('.information-link');
      const image = section.querySelector('.panel-image');

      const timeline = gsap.timeline({
        onStart: () => setIsAnimating(true),
        onComplete: () => setIsAnimating(false),
        onReverseComplete: () => setIsAnimating(false),
        scrollTrigger: {
          trigger: section,
          start: "left center",
          end: "right center",
          scrub: true,
          onEnter: () => {
            gsap.fromTo(title, { autoAlpha: 0, y: -50, visibility: 'hidden' }, { autoAlpha: 1, y: 0, duration: 1, ease: 'power2.out', visibility: 'visible' });
            gsap.fromTo(excerpt, { autoAlpha: 0, y: -50, visibility: 'hidden' }, { autoAlpha: 1, y: 0, duration: 1, delay: 0.5, ease: 'power2.out', visibility: 'visible' });
            gsap.fromTo(link, { autoAlpha: 0, y: -50, visibility: 'hidden' }, { autoAlpha: 1, y: 0, duration: 1, delay: 1, ease: 'power2.out', visibility: 'visible' });
            gsap.fromTo(image, { autoAlpha: 0, x: 50, visibility: 'hidden' }, { autoAlpha: 1, x: 0, duration: 1, delay: 1.5, ease: 'power2.out', visibility: 'visible' });
          },
          onLeave: () => timeline.reverse(),
          onEnterBack: () => timeline.play(),
          onLeaveBack: () => timeline.reverse()
        }
      });

      ScrollTrigger.create({
        trigger: section,
        start: "left center",
        end: "right center",
        onEnter: () => {
          if (timeline.progress() === 0) timeline.play(0);
        },
        onLeaveBack: () => {
          if (timeline.progress() === 1) timeline.reverse();
        }
      });
    });

    gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: container,
        pin: true,
        scrub: 1,
        snap: 1 / (sections.length - 1),
        end: () => `+=${container.offsetWidth}`,
      },
    });
  }, [servicos]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (isAnimating) {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleScroll);
    };
  }, [isAnimating]);

  return (
    <div>
      <div className="container-clinica-area" ref={containerRef}>
        {servicos.map((servico) => (
          <section key={servico.id} className="panel">
            <img src={servico.jetpack_featured_media_url} alt="Clinic Thumbnail" className="panel-image" />
            <div className='information'>
              <span className='information-title'>{servico.title.rendered}</span>
              <span className='information-excerpt' dangerouslySetInnerHTML={{ __html: servico.excerpt.rendered }}></span>
              <span className='information-link'>
                <Link to={`/servicos/${servico.slug}`} className="panel-link">
                  Saber Mais...
                </Link>
              </span>
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default ClinicaArea;