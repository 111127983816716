import React, { useEffect } from 'react';

const PoliticaPrivacidade = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      href="https://www.iubenda.com/privacy-policy/89949188"
      className="iubenda-link"
      title="Política de Privacidade"
    >
      Política de Privacidade
    </a>
  );
};

export default PoliticaPrivacidade
