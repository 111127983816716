import WooConfig from './WooConfig';
import axios from 'axios';

const encodeBase64 = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

const fetchProductDetail = async (id) => {
  const auth = encodeBase64(`${WooConfig.CK_woocommerce}:${WooConfig.CS_woocommerce}`);

  try {
    const response = await axios.get(`${WooConfig.basicUrl}/wp-json/wc/v3/products/${id}`, {
      headers: {
        'Authorization': `Basic ${auth}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar o produto:', error);
    return '';
  }
}

export default fetchProductDetail;