import React, { useContext, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { addProductToCart, initializeCart } from "../../function/function";

const AddToCartButton = (props) => {
    const { product } = props;
    const [cart, setCart] = useContext(AppContext);
    const [setShowViewCart] = useState(false);

    const handleAddToCartClick = () => {
        if (typeof window !== 'undefined') {
            if (!product || !product.price) {
                console.error('Invalid product:', product);
                return;
            }

            let existingCart = localStorage.getItem('woo-next-cart');

            if (existingCart) {
                existingCart = JSON.parse(existingCart);
                const updatedCart = addProductToCart(existingCart, product, 1);
                setCart(updatedCart);
            } else {
                const newCart = initializeCart(product, 1);
                setCart(newCart);
            }

            setShowViewCart(true);
        }
    };

    return (
        <React.Fragment>
            <button onClick={handleAddToCartClick} className="btn-my btn-add">
                Adicionar ao Carrinho
            </button>
        </React.Fragment>
    );
};

export default AddToCartButton;
