// utils/cart.js

// Extrai um valor float de uma string
export const getFloatVal = (string) => {
    let floatValue = string.match(/[+-]?\d+(\.\d+)?/g)[0];
    return (null !== floatValue) ? parseFloat(parseFloat(floatValue).toFixed(2)) : '';
};

// Cria um novo produto com a quantidade e calcula o preço total
export const createNewProduct = (product, qty) => {
    const productPrice = getFloatVal(product.price);
    return {
        productId: product.productId,
        image: product.images,
        name: product.name,
        price: productPrice,
        qty,
        totalPrice: parseFloat((productPrice * qty).toFixed(2))
    };
};

// Inicializa o carrinho com o primeiro produto
export const initializeCart = (product, qty) => {
    const newProduct = createNewProduct(product, qty);
    const newCart = {
        products: [newProduct],
        totalProductsCount: qty,
        totalProductsPrice: newProduct.totalPrice
    };
    localStorage.setItem('woo-next-cart', JSON.stringify(newCart));
    return newCart;
};

// Atualiza o total do carrinho
const updateCartTotals = (cart, updatedProducts) => {
    const totalProductsCount = updatedProducts.reduce((sum, product) => sum + product.qty, 0);
    const totalProductsPrice = updatedProducts.reduce((sum, product) => sum + product.totalPrice, 0);

    const updatedCart = {
        ...cart,
        products: updatedProducts,
        totalProductsCount,
        totalProductsPrice
    };

    localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
    return updatedCart;
};

// Adiciona um produto ao carrinho
export const addProductToCart = (cart, product, qty) => {
    const existingProductIndex = cart.products.findIndex(item => Number(item.productId) === Number(product.productId));

    if (existingProductIndex > -1) {
        const updatedProduct = { ...cart.products[existingProductIndex] };
        updatedProduct.qty += qty;
        updatedProduct.totalPrice = parseFloat((updatedProduct.price * updatedProduct.qty).toFixed(2));
        const updatedProducts = [...cart.products];
        updatedProducts[existingProductIndex] = updatedProduct;
        return updateCartTotals(cart, updatedProducts);
    } else {
        const newProduct = createNewProduct(product, qty);
        const updatedProducts = [...cart.products, newProduct];
        return updateCartTotals(cart, updatedProducts);
    }
};

// Remove um item do carrinho
export const removeItemFromCart = (productId) => {
    let existingCart = localStorage.getItem('woo-next-cart');
    if (!existingCart) return null;

    existingCart = JSON.parse(existingCart);

    // Se não há produtos no carrinho, retorne null
    if (!existingCart.products || existingCart.products.length === 0) {
        return null;
    }

    // Verifica se o produto existe no carrinho
    const productExistsIndex = existingCart.products.findIndex(item => item.productId === productId);

    if (productExistsIndex > -1) {
        // Remove o produto do carrinho
        existingCart.products.splice(productExistsIndex, 1);

        // Atualiza os totais do carrinho
        const updatedCart = updateCartTotals(existingCart, existingCart.products);

        // Se o carrinho estiver vazio, remova-o do local storage
        if (updatedCart.products.length === 0) {
            localStorage.removeItem('woo-next-cart');
            return null;
        }

        // Atualiza o carrinho no local storage
        localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
        return updatedCart;
    } else {
        return existingCart;
    }
};


export const encodeBase64 = (username, password) => {
    const combinedString = `${username}-${password}`;
    const encodedString = btoa(combinedString);
    return encodedString;
};