import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/Navbar-Principal/NavBar';
import Banner from '../Banners/BannerBooks/Banner';
import { useParams } from 'react-router-dom';
import WooConfig from '../../utils/WooConfig';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners
import fetchPostsByCategoryAndSlug from '../../utils/fetchPostsByCategoryAndSlug';
import './TipoClinicaDetail.css';

const TipoClinicaDetail = (props) => {
    const [clinica, setClinica] = useState({});
    const { id } = useParams();
    const [removeLoading, setRemoveLoading] = useState(false);
    const [error, setError] = useState(null);
    const categoryId = 19;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const posts = await fetchPostsByCategoryAndSlug(WooConfig.usernameAdmin, WooConfig.passwordAdmin, categoryId, id);
                if (posts.length > 0) {
                    setClinica(posts[0]);
                } else {
                    setError(new Error('Post not found'));
                }
            } catch (err) {
                setError(err);
            }
        };
        fetchData();
    }, [id]);

    const createClinica = () => {
        return { __html: clinica.content?.rendered || '' };
    };

    useEffect(() => {
        const loadComponents = async () => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 4000));
                setRemoveLoading(true);
            } catch (err) {
                setError(err);
                setRemoveLoading(true);
            }
        };

        loadComponents();
    }, []);

    useEffect(() => {
        if (!removeLoading) {
            document.documentElement.classList.add('no-scroll');
            document.body.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
            document.body.classList.remove('no-scroll');
        }
    }, [removeLoading]);

    if (error) {
        return <div>Error loading page: {error.message}</div>;
    }

    return (
        <>
            {!removeLoading && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    }}
                >
                    <ClipLoader size={50} color={"#123abc"} loading={!removeLoading} /> {/* Usando ClipLoader */}
                </div>
            )}
            {removeLoading && (
                <>
                    <NavBar
                        isLoggedIn={props.isLoggedIn}
                        setIsLoggedIn={props.setIsLoggedIn}
                    />
                    <div className={`app_ClinicaDetail-container contentServicos ${removeLoading ? 'loaded' : ''}`}>
                        <Banner title={clinica.title?.rendered} subtitle={clinica.title?.rendered}/>
                        <div className="app_ClinicaDetail-container-content" dangerouslySetInnerHTML={createClinica()} />
                    </div>
                </>
            )}
        </>
    );
};

export default TipoClinicaDetail;
