import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fetchProductDetail from '../../utils/fetchProductDetail';
import VerifyProductPurchased from '../../utils/VerifyProductPurchased';
import getUserByUsername from '../../utils/GetUserByUsername';
import Navbar from '../NavBar/Navbar-Principal/NavBar';
import ReactStars from 'react-stars';
import './ProductDetail.css';
import CommentsSection from '../CommentSection/CommentSection';
import AddToCartButton from '../Cart/AddtoCartButton';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners

const ProductDetail = (props) => {
  const { id } = useParams();
  const [produto, setProduto] = useState(null);
  const [isPurchased, setIsPurchased] = useState(false);
  const [username] = atob(localStorage.getItem('jwt')).split('-');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserByUsername(username);
        if (response) {
          setUser(response);
        } else {
          console.error('User not found or failed to fetch user');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchData();
  }, [username]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (user && Number(user.user_id) !== -1 && id) {
        try {
          const productData = await fetchProductDetail(id);
          setProduto(productData);
          const purchased = await VerifyProductPurchased({ userId: user.user_id, productId: id });
          setIsPurchased(purchased);
        } catch (error) {
          console.error('Error fetching product data:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProductData();
  }, [user, id]);

  const updateProductData = async () => {
    if (id) {
      try {
        const updatedProductData = await fetchProductDetail(id);
        setProduto(updatedProductData);
      } catch (error) {
        console.error('Failed to update product data:', error);
      }
    }
  };

  const handleDownload = () => {
    if (produto && produto.downloads && produto.downloads[0]) {
      const pdfDownload = produto.downloads[0].file;
      const link = document.createElement('a');
      link.href = pdfDownload;
      link.setAttribute('download', `${produto.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
      </div>
    );
  }

  if (!produto) {
    return <div>No product found</div>;
  }

  const showDownloadButton = isPurchased || Number(produto.price) === 0;

  return (
    <>
      <Navbar isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} />
      <div className='productdetail-main'>
        <div className='productdetail'>
          <div className='productdetail-item'>
            <div className='primary-left-container'>
              <div className='inner-top-container'>
                <div className='image-container'>
                  <div className='image-container-img'>
                    <img src={produto.images[0].src} alt={produto.name} />
                  </div>
                </div>
              </div>
              <div className='produto-support'>
                <div className='produto-title'><h1>{produto.name}</h1></div>
                <div className='produto-price-buying'>
                  {showDownloadButton ? (
                    <button onClick={handleDownload} className='btn-download'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M5 20h14v-2H5v2zm7-18l-5 5h3v4h4v-4h3l-5-5z"/>
                      </svg>
                      <span>Download</span>
                    </button>
                  ) : (
                    <>
                      <AddToCartButton product={ produto }/>
                    </>
                  )}
                </div>
                <div className='produto-sinopse'>
                  <h2>Sinopse</h2>
                  <div dangerouslySetInnerHTML={{ __html: produto.description }} />
                </div>
              </div>
            </div>
            <div className='primary-right-container'>
              <div className='produto-classification'>
                <ReactStars
                  count={5}
                  value={Number(produto.average_rating)}
                  size={24}
                  color2={'#ffd700'}
                  color1={'#e0e0e0'}
                  edit={false}
                />
                <div className='produto-n-reviews'><span>Nº Reviews: </span>{produto.rating_count}</div>
              </div>
            </div>
          </div>
          <CommentsSection productId={id} user={user} updateProductData={updateProductData} />
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
