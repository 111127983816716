import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import './CartIcon.css';

const CartIcon = () => {
    const [cart] = useContext(AppContext);
    const productsCount = (null !== cart && Object.keys(cart).length) ? cart.totalProductsCount : '';
    const totalPrice = (null !== cart && Object.keys(cart).length) ? cart.totalProductsPrice : '';

    return (
        <React.Fragment>
            <Link className="woo-next-cart-wrap" aria-expanded="false" to="/cart">
                {totalPrice ? <span className="woo-next-cart-price">${totalPrice.toFixed(2)}</span> : ''}
                <span className="woo-next-cart-icon-container">
                    <svg
                        className="nav_link_button-icon"
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        height="1.25rem"
                        width="1.25rem"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path
                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                        ></path>
                    </svg>
                    {productsCount ? <span className="woo-next-cart-count">{productsCount}</span> : ''}
                </span>
            </Link>
        </React.Fragment>
    );
}

export default CartIcon;
