import React, { useEffect } from 'react';

const CookiePolicy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      href="https://www.iubenda.com/privacy-policy/89949188/cookie-policy"
      className="iubenda-link"
      title="Política de Cookies"
    >
      Política de Cookies
    </a>
  );
};

export default CookiePolicy;