export default async function fetchPostsByCategoryAndSlug(username, password, categoryId, postSlug) {
    const token = btoa(`${username}:${password}`);

    try {
        // Buscar posts por categoria
        const response = await fetch(`https://claudiasanches.pt/wp-json/wp/v2/posts?categories=${categoryId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch posts');
        }

        const posts = await response.json();

        // Filtrar posts pelo slug
        const filteredPosts = posts.filter(post => post.slug === postSlug);

        return filteredPosts;
    } catch (error) {
        console.error('Error fetching posts:', error);
        return [];
    }
}
