import NavBar from '../NavBar/Navbar-Principal/NavBar';
import Banner from '../Banners/BannerBooks/Banner';
import React, { useState, useEffect } from 'react';
import Carousel from '../Carousel/carouselServicos/carouselServicos';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners
import './TipoClinica.css';

export const TipoClinica = (props) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadComponents = async () => {
      try {
        // Simulando a obtenção de dados com um atraso
        await new Promise((resolve) => setTimeout(resolve, 4000));
        setRemoveLoading(true);
      } catch (err) {
        setError(err);
        setRemoveLoading(true);
      }
    };

    loadComponents();
  }, []);

  useEffect(() => {
    if (!removeLoading) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
      document.body.classList.remove('no-scroll');
    }
  }, [removeLoading]);

  if (error) {
    return <div>Error loading page: {error.message}</div>;
  }

  return (
    <>
      {!removeLoading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <ClipLoader size={50} color={"#123abc"} loading={!removeLoading} /> {/* Usando ClipLoader */}
        </div>
      )}
      <div className={`contentServicos ${removeLoading ? 'loaded' : ''}`}>
        <NavBar isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} />
        <Banner title="Serviços" subtitle="Tipo de Serviços Disponíveis" />
        <Carousel />
      </div>
    </>
  );
}

export default TipoClinica;
