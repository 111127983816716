import axios from 'axios';
import WooConfig from './WooConfig';

// Função para obter o ID do usuário pelo nome de usuário
const getUserByUsername = async (username) => {
  try {
    // Solicitação GET para o endpoint de usuários com parâmetros de autenticação
    const response = await axios.get(`${WooConfig.basicUrl}/?json=users/get_users`, {
      params: {
        u: WooConfig.usernameAdmin,
        p: WooConfig.passwordAdmin
      }
    });

    // Obtenha os dados da resposta
    const data = response.data;

    // Verifica se data é um array ou objeto e ajusta a lógica de filtragem
    const user = Array.isArray(data)
      ? data.find(user => user.user_login === username)
      : Object.values(data).find(user => user.user_login === username);

    // Verifica se encontrou o usuário
    if (user) {
      // Retorna o ID do usuário correspondente
      return user;
    } else {
      console.log('Usuário não encontrado');
      return null;
    }
  } catch (error) {
    // Melhorar a captura de erros
    if (error.response) {
      console.error('Erro ao obter o ID do usuário:', error.response.data);
    } else {
      console.error('Erro ao obter o ID do usuário:', error.message);
    }
    return null;
  }
};

export default getUserByUsername;
