import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/Navbar-Principal/NavBar';
import CartItemsContainer from './cart-page/CartItemsContainer';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners

const Cart = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCartItems = async () => {
      try {
        // Simulando uma chamada de carregamento de dados do carrinho
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simula carregamento
        setLoading(false);
      } catch (error) {
        console.error("Error loading cart items:", error);
        setLoading(false);
      }
    };

    loadCartItems();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
      </div>
    );
  }

  return (
    <>
      <NavBar isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} />
      <CartItemsContainer />
    </>
  );
}

export default Cart;
