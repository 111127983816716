import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Blog from './components/Blog/Blog';
import BlogDetail from './components/BlogDetail/BlogDetail';
import TipoClinica from './components/TipoClinica/TipoClinica';
import TipoClinicaDetail from './components/TipoClinicaDetail/TipoClinicaDetail';
import Loja from "./components/LojaEbooks/Loja";
import Checkout from './components/Checkout/Checkout';

import './App.css';
import ProductDetail from './components/ProdutoDetail/ProductDetail';
import Cart from './components/Cart/Cart';

function App() {
  const [username, setUsername] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const sharedProps = {
    isLoggedIn,
    setIsLoggedIn,
    username,
    setUsername,
    serverMessage,
    setServerMessage
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout {...sharedProps} />} />
        <Route path="/login" element={<Login {...sharedProps} />} />
        <Route path="/register" element={<Register {...sharedProps} />} />
        <Route path="/blog" element={<Blog isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/blog/:id" element={<BlogDetail isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/servicos" element={<TipoClinica isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/servicos/:id" element={<TipoClinicaDetail isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/produtos" element={<Loja isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/produto/:slug/:id' element={<ProductDetail isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} username={username} />} />
        <Route path="/cart" element={<Cart isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/checkout' element={<Checkout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}/>
      </Routes>
    </div>
  );
}

export default App;
