import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './Banner.css';
import { Link } from 'react-router-dom';

function Banner({isLoggedIn}) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const [animate, setAnimate] = useState('hidden');

  useEffect(() => {
    if (inView) {
      setAnimate('fadeInUp');
    } else {
      setAnimate('fadeOutDown');
    }
  }, [inView]);

  return (
    <div className={`bannerloja-container ${animate}`} ref={ref}>
      <div className="bannerloja-content">
        <p className="bannerloja-title">Loja Online</p>
        {isLoggedIn && (
          <Link className="bannerloja-button" to="/produtos">Go To</Link>
        )}
        {!isLoggedIn && (
          <Link className="bannerloja-button" to="/login">Sign In</Link>
        )}
      </div>
    </div>
  );
}

export default Banner;
