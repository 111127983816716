import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";
import NavBar from "../NavBar/Navbar-Principal/NavBar";
import WooConfig from "../../utils/WooConfig";
import fetchPostsByCategory from "../../utils/fetchServicos";
import fetchTags from "../../utils/fetchTags";
import "./Blog.css";
import Banner from "../Banners/BannerBooks/Banner";
import ClipLoader from "react-spinners/ClipLoader"; // Importando o ClipLoader do react-spinners

const Blog = (props) => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(6);
  const [loading, setLoading] = useState(true);
  const [tagsMap, setTagsMap] = useState({});
  const categoryId = 20;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await fetchPostsByCategory(
          WooConfig.usernameAdmin,
          WooConfig.passwordAdmin,
          categoryId
        );
        const tags = await fetchTags(
          WooConfig.usernameAdmin,
          WooConfig.passwordAdmin
        );
        const tagMap = tags.reduce((acc, tag) => {
          acc[tag.id] = tag.name;
          return acc;
        }, {});

        setBlogs(posts);
        setTagsMap(tagMap);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getTagNamesByIds = (ids, tagMap) => {
    return ids.map((id) => tagMap[id] || "Tag não encontrada");
  };

  const capitalizeFirstLetter = (word) => {
    return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} />{" "}
        {/* Usando ClipLoader */}
      </div>
    );
  }

  const BlogCard = ({ blogPost }) => {
    const getTagNames = (ids) => getTagNamesByIds(ids, tagsMap);

    return (
      <div key={blogPost.id} className="post_blog col-md-3">
        <div className="app_blog-other-card">
          <div className="app_blog-other-card-image">
            <img src={blogPost.jetpack_featured_media_url} alt="thumbnail" />
          </div>
          <div className="app_blog-other-card-text">
            {getTagNames(blogPost.tags).map((tagName, index) => (
              <strong
                key={index}
                className="app_blog-other-card-text-category"
                data-tooltip={tagName}
              >
                {capitalizeFirstLetter(tagName)}
              </strong>
            ))}
            <h3 className="app_blog-other-card-text-title">
              {blogPost.title.rendered}
            </h3>
            <div className="app_blog-other-card-text-date">
              {blogPost.date.split("T")[0]}
            </div>
            <p
              className="app_blog-other-card-text-except"
              dangerouslySetInnerHTML={{ __html: blogPost.excerpt.rendered }}
            />
            <p className="app_blog-other-card-text-links">
              <Link
                to={`/blog/${blogPost.slug}`}
                className="app_blog-other-card-text-links-link"
              >
                Saber Mais
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavBar
        isLoggedIn={props.isLoggedIn}
        setIsLoggedIn={props.setIsLoggedIn}
      />
      <Banner title="Notícias" subtitle="Notícias" />
      {currentBlogs.length > 0 ? (
        <div className="blogs_posts">
          {currentBlogs.map((blogPost) => (
            <BlogCard key={blogPost.id} blogPost={blogPost} />
          ))}
        </div>
      ) : (
        <div className="alert-blogs">
          <p>
            <FaExclamationCircle
              style={{ color: "#173463", fontSize: "20px" }}
            />
            <span>Não existe notícias publicadas</span>
          </p>
        </div>
      )}
      {currentBlogs.length > 0 ? (
        <nav aria-label="Navigation Page">
          <ul className="app_blog-pageNavigation">
            {currentPage > 1 && (
              <li className="app_blog-pageNavigation-item">
                <Link
                  className="app_blog-pageNavigation-item-link"
                  aria-label="Previous"
                  onClick={() => paginate(currentPage - 1)}
                >
                  <span aria-hidden="true">Previous</span>
                </Link>
              </li>
            )}
            {Array.from(
              { length: Math.ceil(blogs.length / blogsPerPage) },
              (_, i) => (
                <li
                  key={i}
                  className={`app_blog-pageNavigation-item ${
                    i + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <Link
                    className="app_blog-pageNavigation-item-link"
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </Link>
                </li>
              )
            )}
            {currentPage < Math.ceil(blogs.length / blogsPerPage) && (
              <li className="app_blog-pageNavigation-item">
                <Link
                  className="app_blog-pageNavigation-item-link"
                  aria-label="Next"
                  onClick={() => paginate(currentPage + 1)}
                >
                  <span aria-hidden="true">Next</span>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      ) : (
        ""
      )}
    </div>
  );
};

export default Blog;
