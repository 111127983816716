import React, { Component } from 'react';
import { BiSolidEnvelope, BiSolidLockOpenAlt } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import images from '../../constantes/images';
import axios from 'axios';
import { encodeBase64 } from '../../function/function';
import ClipLoader from "react-spinners/ClipLoader"; // Importando o ClipLoader do react-spinners

import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false, // Estado de carregamento
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    async loginGet(navigate) {
        this.setState({ loading: true }); // Inicia o carregamento

        try {
            const res = await axios.get(`https://claudiasanches.pt/?json=users/login&user_login=${this.state.username}&user_password=${this.state.password}`);
            localStorage.setItem('jwt', encodeBase64(`${this.state.username}-${this.state.password}`)); // Salva o token no localStorage
            toast.success('Successfully Logged In', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            this.props.setIsLoggedIn(true);
            this.props.setUsername(`${this.state.username}`);
            setTimeout(() => {
                navigate('/'); // Redireciona para a página inicial após 2 segundos
                this.setState({ loading: false }); // Encerra o carregamento
            }, 2000);
        } catch (error) {
            console.log(error);
            toast.error('Error logging in: ' + (error.response ? error.response.data.error : error.message), {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            this.setState({ loading: false }); // Encerra o carregamento em caso de erro
        }
    }

    handleSubmit(e, navigate) {
        e.preventDefault();
        this.loginGet(navigate);
    }

    render() {
        const { loading } = this.state;

        return (
            <>
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}
                    >
                        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
                    </div>
                ) : (
                    <LoginForm state={this.state} handleChange={this.handleChange} handleSubmit={this.handleSubmit} />
                )}
                <ToastContainer />
            </>
        );
    }
}

const LoginForm = ({ state, handleChange, handleSubmit }) => {
    const navigate = useNavigate();

    return (
        <div className="app_login-container">
            <div className="app_login-right">
                <h2>Login</h2>
                <p>
                    Don't have an account? <Link to="/register">Register</Link>
                </p>
                <div className="app_login-form">
                    <form onSubmit={(e) => handleSubmit(e, navigate)}>
                        <div className="app_login-input-box">
                            <span className="icon">
                                <BiSolidEnvelope />
                            </span>
                            <input type="text" name="username" value={state.username} onChange={handleChange} />
                            <label>Username</label>
                        </div>
                        <div className="app_login-input-box">
                            <span className="icon">
                                <BiSolidLockOpenAlt />
                            </span>
                            <input type="password" name="password" value={state.password} onChange={handleChange} />
                            <label>Password</label>
                        </div>
                        <div className="app_login-button">
                            <button type="submit" className="app_login-btnlog">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
                <div className="app_login-logo">
                    <Link to="/" className="app_login-logo-link">
                        <img src={images.logo_horizontal} alt="" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
