import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar/Navbar-Principal/NavBar';
import Menu from '../Menus/Menu-Loja/Menu';
import fetchProducts from '../../utils/fetchProducts';
import Products from '../Products/Products-loja/Products';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners
import './loja.css';
import Banner from '../Banners/BannerBooks/Banner';
import { FaExclamationCircle } from 'react-icons/fa';

const Loja = (props) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const productsData = await fetchProducts();
        if (productsData) {
          setProducts(productsData);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
      </div>
    );
  }

  return (
    <>
      <NavBar isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} />
      <Banner title="Loja" subtitle="Livros" />
      <Menu />
      <div className='products_loja'>
        <div className='products_loja-views'>
          {products.length > 0 ? (
            products.map(product => (
              <Products key={product.id} product={product} />
            ))
          ) : (
            <div className='alert-blogs'>
              <p>
                <FaExclamationCircle style={{ color: '#173463', fontSize: '20px' }} />
                <span>Não existem produtos a serem vendidos</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Loja;
