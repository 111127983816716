import React, {useContext} from "react";
import { AppContext } from "../../../context/AppContext"
import CartItem from "./CartItem";
import './CarItemsContainer.css';
import Banner from "../../Banners/BannerBooks/Banner";
import { removeItemFromCart } from "../../../function/function";
import { Link } from "react-router-dom";

const CartItemsContainer = () =>{

    const [cart, setCart] = useContext( AppContext );
    console.warn( cart );

    const handleRemoveProduct = (event, productId) => {
        const updatedCart = removeItemFromCart( productId );
        setCart( updatedCart );
    }

    return(
        <>
            { cart ? (
                <div className="woo-next-cart-wrapper container">
                    <Banner title="Carrinho" subtitle="Carrinho" />
                    <table className="woo-next-cart-total-table">
                        <thead>
                            <tr className="woo-next-cart-header-container">
                                <th className="woo-next-cart-heading" scope="col"/>
                                <th className="woo-next-cart-heading" scope="col"/>
                                <th className="woo-next-cart-heading" scope="col">Produto</th>
                                <th className="woo-next-cart-heading" scope="col">Preço</th>
                                <th className="woo-next-cart-heading" scope="col">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            { cart.products.length && (
                                cart.products.map(item => (
                                    <CartItem
                                        key={item.produtoId}
                                        item={ item }
                                        handleRemoveProduct = {handleRemoveProduct}
                                        setCart={ setCart }
                                    />
                                ))
                            )}
                        </tbody>
                    </table>

                    <div className='woo-next-cart-total-container'>
                        <div className='woo-next-cart-total-container-col'>
                            <table className='table table-hover'>
                                <tr className='table-light'>
                                    <td className='woo-next-cart-element-total'>Subtotal</td>
                                    <td className='woo-next-cart-element-amt'>{cart.totalProductsPrice.toFixed(2)}</td>
                                </tr>
                                <tr className='table-light'>
                                    <td className='woo-next-cart-element-total'>Total</td>
                                    <td className='woo-next-cart-element-amt'>{cart.totalProductsPrice.toFixed(2)}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <Link to="/checkout" className="link-checkout">
                        <button className="btn-checkout">
                            <span className="woo-next-cart-checkout-text">Proceed to Checkout</span>
                        </button>
                    </Link>
                </div>
            ): ''}
        </>
    );
}

export default CartItemsContainer;
