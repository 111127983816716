import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import images from '../../../constantes/images';
import '../NavBar.css';
import CartIcon from '../../Cart/CartIcon';

function NavBar(props) {
  useEffect(() => {
    const userJWT = localStorage.getItem('jwt') || '';
    props.setIsLoggedIn(userJWT.length > 0);
  }, [props]);

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('woo-next-cart')
    props.setIsLoggedIn(false);
    props.setUsername('');
    props.setServerMessage('You have been logged out.');
  };

  return (
    <div className="app__navBar navbar navbar-expand-lg">
      <div className="app__navBar-logo navbar">
        <img src={images.logo} alt="logo" />
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end text-center" id="navbarNav">
        <ul className="app__navBar-links navbar-nav">
          <li className="p__opensans nav-item">
            <Link className="nav-link" to="/servicos">
              Serviços
            </Link>
          </li>
          <li className="p__opensans nav-item">
            <Link className="nav-link" to="/blog">
              Notícias
            </Link>
          </li>
          {!props.isLoggedIn ? (
            <>
              <li className="p__opensans nav-item">
                <Link className="nav-link" to="/">
                  <span>Home</span>
                </Link>
              </li>
              <li className="p__opensans nav-item">
                <Link className="nav-link" to="/login">
                  <span>Login</span>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="p__opensans nav-item">
                <Link className="nav-link" to="/produtos">
                  Loja E-Books
                </Link>
              </li>
              <li className="p__opensans nav-item">
                <div className="nav-button-container">
                  <Link className="nav_link-button" aria-expanded="false" to="/">
                    <svg
                      className="nav_link_button-icon"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 1024 1024"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"
                      ></path>
                    </svg>
                  </Link>
                  <CartIcon />
                  <Link className="nav_link-button" aria-expanded="false" to="/" onClick={handleLogout}>
                    <svg
                      className="nav_link_button-icon"
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 16l-4-4 4-4M5 12h14M15 3H3v18h12" />
                    </svg>
                  </Link>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default NavBar;
