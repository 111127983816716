import React from 'react';
import './Banner.css';

const Banner = ({title, subtitle}) => {
  return (
    <div className="banner">
      <h1>{title}</h1>
      <div className="breadcrumb"></div>
      <span className='breadcrumb_span'>{subtitle}</span>
    </div>
  );
}

export default Banner;
