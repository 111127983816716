import React from 'react';
import { MdDelete } from "react-icons/md";
import { useState } from 'react';
import './CartItem.css';

const CartItem = ({item, setCart, handleRemoveProduct}) => {

  const [ productCount, setProductCount ] = useState( item.qty );

  return (
    <tr className='woo-next-cart-item' key={item.productId}>
      <th className="woo-next-cart-element woo-next-cart-el-close">
        <span className='woo-next-cart-close-icon'>
          <MdDelete className='icon' onClick={(event) => handleRemoveProduct( event, item.productId )}/>
        </span>
      </th>
      <td className="woo-next-cart-element woo-next-cart-el-image">
        <img width='64' src={item.image[0].src} alt={item.image[0].name} />
      </td>
      <td className="woo-next-cart-element">
        {item.name}
      </td>
      <td className="woo-next-cart-element">
        {item.price.toFixed(2)}
      </td>
      <td className="woo-next-cart-element">
        <span>{item.qty}</span>
      </td>
    </tr>
  )
};

export default CartItem;
