export default async function fetchPostsByCategory(username, password, categoryId) {
    const token = btoa(`${username}:${password}`);

    const response = await fetch(`https://claudiasanches.pt/wp-json/wp/v2/posts?categories=${categoryId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Basic ${token}`
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch posts');
    }

    const data = await response.json();
    return data;
}
