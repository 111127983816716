import React from 'react';
import images from '../../constantes/images';
import { BsInstagram } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from 'react-router-dom';


import './About.css'

const About = () => {
  return (
    <div className='d-flex justify-content-center align-items-center py-5 app__About'>
        <div className='container app_About-container'>
            <div className='app__About-cardbox'>
                <div className='app__About-box'>
                    <div className='card-body '>
                        <h3 className='card-title'>Bem-vindo(a) ao meu site!</h3>
                        <p className='card-text'>Sou a Cláudia Sanches, Fisioterapeuta com experiência em reabilitação
                            músculo-esquelética e desportiva. A minha missão é ajudá-lo(a) a alcançar uma vida mais
                            saudável, ativa e sem dor, utilizando uma abordagem personalizada e integrativa.
                            Combino a terapia manual integrativa, o exercício terapêutico e a mobilidade para tratar
                            uma ampla variedade de condições e melhorar a sua qualidade de vida. Acredito que cada
                            paciente é único e merece um plano de tratamento que considere todas as suas
                            necessidades individuais.
                            Além da minha formação em Fisioterapia, sou também treinadora de taekwondo, sou
                            certificada em pilates clínico e possuo uma pós-graduação em strength and conditioning
                            (treino de força). Essa combinação de conhecimentos permite-me oferecer programas de
                            reabilitação e condicionamento físico que não apenas tratam lesões, mas também
                            ajudam a prevenir novas ocorrências, a melhorar a sua postura, a criar consciência corporal
                            e a melhorar o seu desempenho desportivo.
                            Estou aqui para ajudá-lo(a) a alcançar os seus objetivos de saúde e bem-estar. Seja para
                            tratar uma lesão, melhorar o seu desempenho desportivo ou simplesmente adotar um estilo
                            de vida mais ativo e saudável, estou pronta para acompanhá-lo(a) nessa sua jornada!
                        </p>
                        <h6 className='card-subtitle mt-4 mb-2'>Entre em contato para agendar uma consulta ou saber mais sobre os meus serviços.</h6>
                    </div>
                    <div className='redes-sociais-about'>
                        <Link to="https://wa.me/351913314179"><BsWhatsapp size={28} className='socialmedia-logo'/></Link>
                        <Link to="https://www.instagram.com/ft.claudiasanches/"><BsInstagram size={28} className='socialmedia-logo'/></Link>
                    </div>
                </div>
                <div className='app__About-cardbox-profile'>
                    <img src={images.image_About} alt=''/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About