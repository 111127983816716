import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import WooConfig from "../../../utils/WooConfig";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";
import './carouselServicos.css';
import { Link } from "react-router-dom";
import fetchPostsByCategory from "../../../utils/fetchServicos";

function Carousel() {
  const [slides, setSlides] = useState([]);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const categoryId = 19;

  useEffect(() => {
    const fetchClinicas = async () => {
      try {
        const posts = await fetchPostsByCategory(WooConfig.usernameAdmin, WooConfig.passwordAdmin, categoryId);
        setSlides(posts);
      } catch (error) {
        console.error('Error fetching clinicas:', error);
      }
    };

    fetchClinicas();
  }, []);

  useEffect(() => {
    if (swiperInstance && slides.length > 0) {
      swiperInstance.update();
    }
  }, [swiperInstance, slides]);

  useEffect(() => {
    const initializeSwiper = () => {
      const timer = setTimeout(() => {
        if (swiperInstance && slides.length > 0) {
          swiperInstance.update();
        }
      }, 100);

      return () => clearTimeout(timer);
    };

    initializeSwiper();
  }, [swiperInstance, slides]);

  if (slides.length === 0) {
    return null; // Pode retornar um indicador de carregamento aqui se desejar
  }

  return (
    <section className="page carousel-1-page">
      <Swiper
        onSwiper={setSwiperInstance}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={2}
        spaceBetween={20} // Espaço entre os slides
        effect="coverflow"
        loop={true}
        autoplay={{ delay: 5000 }}
        speed={800} // Velocidade da transição em milissegundos
        pagination={{ clickable: true }}
        coverflowEffect={{
          rotate: 50, // Ângulo de rotação dos slides
          stretch: 0,
          depth: 200, // Profundidade dos slides
          modifier: 1, // Modificador da perspectiva
          slideShadows: true, // Adicionar sombras aos slides
        }}
        modules={[Pagination, EffectCoverflow, Autoplay]}
      >
        {slides.map((slide) => (
          <SwiperSlide
            key={slide.title?.rendered}
            className="carousel-1-slide"
            style={{
              backgroundImage: `url(${slide.jetpack_featured_media_url})`,
            }}
          >
            <div className="carousel-slide-content">
              <h2 className="slide-title">{slide.title?.rendered}</h2>
              <Link to={`/servicos/${slide.slug}`} className="slide-link">Saber Mais</Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default Carousel;
