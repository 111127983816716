import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CommentSection.css';
import WooConfig from '../../utils/WooConfig';
import ReactStars from 'react-stars';

const encodeBase64 = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

const CommentsSection = ({ productId, user, updateProductData }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newRating, setNewRating] = useState(0);
  const [loading, setLoading] = useState(true);

  const auth = encodeBase64(`${WooConfig.CK_woocommerce}:${WooConfig.CS_woocommerce}`);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${WooConfig.basicUrl}/wp-json/wc/v3/products/reviews`, {
          headers: {
            'Authorization': `Basic ${auth}`
          }
        });

        const filteredComments = response.data.filter(comment => comment.product_id === Number(productId));
        setComments(filteredComments);
      } catch (error) {
        console.error('Failed to fetch comments:', error.response ? error.response.data : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [auth, productId]);

  const handleAddComment = async () => {
    if (newComment.trim() && newRating > 0) {
      try {
        await axios.post(`${WooConfig.basicUrl}/wp-json/wc/v3/products/reviews`, {
          product_id: productId,
          review: newComment,
          reviewer: user.user_login,
          reviewer_email: user.user_email,
          rating: newRating
        }, {
          headers: {
            'Authorization': `Basic ${auth}`,
            'Content-Type': 'application/json'
          }
        });

        // Atualizar a lista de comentários e os dados do produto
        await updateCommentsAndProduct();

        // Limpar os campos
        setNewComment('');
        setNewRating(0);
      } catch (error) {
        console.error('Failed to add comment:', error.response ? error.response.data : error.message);
      }
    }
  };

  const updateCommentsAndProduct = async () => {
    try {
      // Atualizar a lista de comentários
      const updatedCommentsResponse = await axios.get(`${WooConfig.basicUrl}/wp-json/wc/v3/products/reviews`, {
        headers: {
          'Authorization': `Basic ${auth}`
        }
      });

      const updatedComments = updatedCommentsResponse.data.filter(comment => comment.product_id === Number(productId));
      setComments(updatedComments);

      // Atualizar os dados do produto
      await updateProductData();
    } catch (error) {
      console.error('Failed to update comments and product data:', error.response ? error.response.data : error.message);
    }
  };

  if (loading) {
    return <div>Loading comments...</div>;
  }

  return (
    <div className='comments-section'>
      <h2>Comentários</h2>
      <div className='comments-list'>
        {comments.length > 0 ? (
          comments.map((comment) => (
            <div key={comment.id} className='comment'>
              <strong>{comment.reviewer}</strong>
              <div className='comment-content' dangerouslySetInnerHTML={{ __html: comment.review }} />
              <ReactStars
                count={5}
                value={comment.rating}
                size={24}
                color2={'#ffd700'}
                edit={false}
              />
            </div>
          ))
        ) : (
          <div>No comments yet.</div>
        )}
      </div>
      <div className='comment-form'>
        <h4>Deixe um comentário</h4>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder='Adicione um comentário...'
        />
        <ReactStars
          count={5}
          value={newRating}
          onChange={(newRating) => setNewRating(Math.round(newRating))}
          size={24}
          color2={'#ffd700'}
          half={false}
        />
        <button onClick={handleAddComment}>Adicionar Comentário</button>
      </div>
    </div>
  );
};

export default CommentsSection;
