import logo from '../static/uploads/logo.png';
import image_1 from '../static/uploads/image-1.jpg';
import image_2 from '../static/uploads/image-2.jpg';
import image_3 from '../static/uploads/image-3.jpg';
import logo_horizontal from '../static/uploads/Logotipo azul sem fundo.png';
import image_About from '../static/uploads/WhatsApp Image 2024-04-14 at 08.52.54.jpeg';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logo, image_1, image_2, image_3,logo_horizontal,image_About,
};