import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar/Navbar-Principal/NavBar';
import ClipLoader from "react-spinners/ClipLoader"; // Importando ClipLoader do react-spinners

const Checkout = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula o carregamento de dados
    const simulateLoading = async () => {
      try {
        // Simula um atraso para o carregamento
        await new Promise(resolve => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error loading checkout page:", error);
        setLoading(false);
      }
    };

    simulateLoading();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1000,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> {/* Usando ClipLoader */}
      </div>
    );
  }

  return (
    <>
      <NavBar isLoggedIn={props.isLoggedIn} setIsLoggedIn={props.setIsLoggedIn} />
      <div>Coming Soon...</div>
    </>
  );
};

export default Checkout;
