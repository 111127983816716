import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/Navbar-Principal/NavBar";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import "./BlogDetail.css";
import fetchPostsByCategoryAndSlug from "../../utils/fetchPostsByCategoryAndSlug";
import WooConfig from "../../utils/WooConfig";
import Banner from "../Banners/BannerBooks/Banner";

const BlogDetail = (props) => {
  const [blog, setBlog] = useState({});
  const [removeLoading, setRemoveLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const categoryId = 20; // Categoria específica para o blog

  useEffect(() => {
    const fetchData = async () => {
      try {
        const posts = await fetchPostsByCategoryAndSlug(
          WooConfig.usernameAdmin,
          WooConfig.passwordAdmin,
          categoryId,
          id
        );
        if (posts.length > 0) {
          setBlog(posts[0]);
        } else {
          setError(new Error("Post not found"));
        }
      } catch (err) {
        setError(err);
      }
    };
    fetchData();
  }, [id]);

  const createBlog = () => {
    return { __html: blog.content?.rendered || "" };
  };

  useEffect(() => {
    const loadComponents = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 4000));
        setRemoveLoading(true);
      } catch (err) {
        setError(err);
        setRemoveLoading(true);
      }
    };

    loadComponents();
  }, []);

  useEffect(() => {
    if (!removeLoading) {
      document.documentElement.classList.add("no-scroll");
      document.body.classList.add("no-scroll");
    } else {
      document.documentElement.classList.remove("no-scroll");
      document.body.classList.remove("no-scroll");
    }
  }, [removeLoading]);

  if (error) {
    return <div>Error loading page: {error.message}</div>;
  }

  return (
    <>
      {!removeLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ClipLoader size={50} color={"#123abc"} loading={!removeLoading} />
        </div>
      )}
      {removeLoading && (
        <>
          <NavBar
            isLoggedIn={props.isLoggedIn}
            setIsLoggedIn={props.setIsLoggedIn}
          />
          <div className="app_blogDetail-container contentServicos">
            <Banner
              title={blog.title?.rendered}
              subtitle={blog.date.split("T")[0]}
            />
            <div
              className="app_blogDetail-container-content"
              dangerouslySetInnerHTML={createBlog()}
            />
          </div>
        </>
      )}
    </>
  );
};

export default BlogDetail;
