import React from "react";
import videos from "../../constantes/videos";

import "./Slideshow.css";

const SlideShow = () => {
  return (
    <div className="d-flex justify-content-center align-items-center app__slideShow">
      <div className="">
        {" "}
        <video autoPlay loop playsInline muted className="opacity-50 fade">
          <source src={videos.slideshowVideo} type="video/mp4" />
        </video>
      </div>
      <div className="position-absolute text-center fw-bold lead app__slideShow-text nobackground">
        <span>
          Juntos, podemos transformar a sua saúde e qualidade de vida!
        </span>
      </div>
    </div>
  );
};

export default SlideShow;
