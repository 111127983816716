import WooConfig from './WooConfig';
import axios from 'axios';

// Função para codificar em base64 no navegador
const encodeBase64 = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

const fetchProducts = async () => {
  const auth = encodeBase64(`${WooConfig.CK_woocommerce}:${WooConfig.CS_woocommerce}`);

  try {
    const response = await axios.get(`${WooConfig.basicUrl}/wp-json/wc/v3/products`, {
      headers: {
        'Authorization': `Basic ${auth}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar os produtos:', error);
    return '';
  }
}

export default fetchProducts;
