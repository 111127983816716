import axios from 'axios';
import WooConfig from './WooConfig';

// Função para codificar a string em Base64
const encodeBase64 = (str) => {
  return btoa(unescape(encodeURIComponent(str)));
};

// Função para verificar se um produto foi comprado por um usuário específico
const VerifyProductPurchased = async ({ userId, productId }) => {
  const auth = encodeBase64(`${WooConfig.CK_woocommerce}:${WooConfig.CS_woocommerce}`);

  // Definindo a URL base para a API de pedidos
  const baseUrl = `${WooConfig.basicUrl}/wp-json/wc/v3/orders`;

  try {
    // Solicitação GET para o endpoint de pedidos com parâmetros de consulta
    const response = await axios.get(baseUrl, {
      headers: {
        'Authorization': `Basic ${auth}`,
      },
      params: {
        customer: userId
      }
    });

    const orders = response.data;

    // Verificando se o produto foi comprado
    const productPurchased = orders.some(order =>
      order.line_items.some(item => Number(item.product_id) === Number(productId))
    );

    return productPurchased;
  } catch (error) {
    console.error('Erro ao verificar se o produto foi comprado:', error);
    return false;
  }

};

export default VerifyProductPurchased;
