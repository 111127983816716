import React from 'react';
import { Link } from 'react-router-dom';
import '../Products.css'; /* Importa o CSS do card de produto */

const Products = ({ product }) => {
  const displayPrice = product.price && product.price !== "0" ? `${product.price} €` : 'Grátis';

  return (
    <div className='products_loja-card'>
      <div className='products_loja-card_img'>
        <div className='products_loja-card_img-img'>
          <img src={product.images[0].src} alt={product.name} />
        </div>
      </div>
      <div className="products_loja-card_title">{product.name}</div>
      <div
        className="products_loja-card_subtitle"
        dangerouslySetInnerHTML={{ __html: product.description }}
      />
      <hr className="products_loja-card_divider" />
      <div className="products_loja-card_footer">
        <div className="products_loja-card_price">{displayPrice}</div>
        <Link to={`/produto/${product.slug}/${product.id}`} className="products_loja-card_btn">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="m397.78 316h-205.13a15 15 0 0 1 -14.65-11.67l-34.54-150.48a15 15 0 0 1 14.62-18.36h274.27a15 15 0 0 1 14.65 18.36l-34.6 150.48a15 15 0 0 1 -14.62 11.67zm-193.19-30h181.25l27.67-120.48h-236.6z"></path><path d="m222 450a57.48 57.48 0 1 1 57.48-57.48 57.54 57.54 0 0 1 -57.48 57.48zm0-84.95a27.48 27.48 0 1 0 27.48 27.47 27.5 27.5 0 0 0 -27.48-27.47z"></path><path d="m368.42 450a57.48 57.48 0 1 1 57.48-57.48 57.54 57.54 0 0 1 -57.48 57.48zm0-84.95a27.48 27.48 0 1 0 27.48 27.47 27.5 27.5 0 0 0 -27.48-27.47z"></path><path d="m158.08 165.49a15 15 0 0 1 -14.23-10.26l-25.71-77.23h-47.44a15 15 0 1 1 0-30h58.3a15 15 0 0 1 14.23 10.26l29.13 87.49a15 15 0 0 1 -14.23 19.74z"></path></svg>
        </Link>
      </div>
    </div>
  );
};

export default Products;
