import React from "react";
import { BsHouseDoorFill } from "react-icons/bs";
import { BiMobile } from "react-icons/bi";
import { SiMinutemailer } from "react-icons/si";
import { BsClockFill } from "react-icons/bs";
import PoliticaPrivacidade from "../PoliticaPrivacidade";
import CookiePolicy from "../CoookiePolicy";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="app__Footer">
      <div className="app__Footer-info">
        <div className="app__Footer-colorText">
          <p className=" font-family-serif font-italic fw-bold ">Contactos</p>
          <ul className="list-unstyled list-group fw-bold app__Footer-contacts">
            <li className="list-group-item border-0">
              <BsHouseDoorFill size={20} className="app__Footer-icon" />
              <span>Cascais & Oeiras, Portugal</span>
            </li>
            <li className="list-group-item border-0">
              <BiMobile size={20} className="app__Footer-icon" />
              <span>+351 913 314 179 (rede móvel nacional)</span>
            </li>
            <li className="list-group-item border-0">
              <SiMinutemailer size={20} className="app__Footer-icon" />
              <span>hello@claudiasanches.pt</span>
            </li>
          </ul>
        </div>
        <div className=" app__Footer-colorText">
          <p className="font-family-serif font-italic fw-bold ">Horário</p>
          <ul className="list-unstyled list-group fw-bold app__Footer-time">
            <li className="list-group-item border-0">
              <BsClockFill size={20} className="app__Footer-icon" />
              <span>
                <span>2ª, 4ª e 6ª feira em Oeiras</span>
                <span>3ª e 5 ª feira em Cascais</span>
                <span>Horários consoante marcação</span>
              </span>
            </li>
          </ul>
        </div>
        <div className=" app__Footer-colorText">
          <p className="font-italic fw-bold ">Links</p>
          <ul className="list-unstyled list-group fw-bold app__Footer-links">
            {/* <li className='list-group-item border-0'>
                            <a href="#Exemplo1">Livro de Reclamações Online</a>
                        </li>
                        <li className='list-group-item border-0'>
                            <a href="#Exemplo1">Livro de Elogios Online</a>
                        </li> */}
            <li className="list-group-item border-0">
              <PoliticaPrivacidade />
            </li>
            <li className="list-group-item border-0">
              <CookiePolicy />
            </li>
          </ul>
        </div>
      </div>
      <div
        className="text-center fw-bold small mt-5"
        style={{ color: "#44aab8" }}
      >
        <p>
          &copy; 2024 ClaudiaSanches. Todos os direitos reservados. Desenvolvido
          por
          <a
            href="https://tiagopedrotkd.vercel.app"
            target="_blank"
            rel="noopener noreferrer"
            className="link-tiago-pedro"
          >
            Tiago Pedro
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
